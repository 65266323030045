import React from "react";
import { useNavigate } from "react-router-dom";
import Phone from "../assets/phone.svg";
import "./Layout.css";
const assetPath = "https://neurobridge-public.objectstore.e2enetworks.net";
const Logo = assetPath + "/aiml/logo.svg";
// const Logout = assetPath + "/aiml/logout.svg";

const LayoutPrivacy = () => {
  // const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();

  const handleCloseButton = () => {
    navigate("/");
  };

  const navigateAPIDoc = (tab) => {
    if (tab === "docs") {
      window.open("http://api.ai.ml/docs/swagger/", "_blank");
    } else {
      window.open("https://community.ai.ml/", "_blank");
    }
  };
  // const handleLogoutDrop = () => {
  //   setIsActive(!isActive);
  // };
  return (
    <div>
      <div>
        <header className="header-wrapper mobile-header-active">
          <div className="header-logo">
            <img
              src={Logo}
              className="logo"
              alt="logo"
              onClick={() => navigate("/")}
            />
          </div>

          <div className="nav-links">
            <ul>
              <li>
                <a
                  className={`footer_sider_bar`}
                  href="https://console.ai.ml/dashboard"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="https://console.ai.ml/playground/chat"
                  className={`footer_sider_bar`}
                >
                  Playground
                </a>
              </li>
              <li>
                <a
                  href="https://console.ai.ml/setting/yourOrganisation"
                  className={`footer_sider_bar `}
                >
                  Settings
                </a>
              </li>
            </ul>
          </div>
          <div className="user-settings-main">
            <div
              onClick={() => navigateAPIDoc("community")}
              className="docs-cum"
            >
              Community
            </div>
            <div onClick={() => navigateAPIDoc("docs")} className="docs-cum">
              Docs
            </div>
            {/* <div className="logout-wrap">
              <img
                src={Logout}
                onClick={handleLogoutDrop}
                className="logout-icon"
                alt="logoutIcon"
              />

              {isActive ? (
                <ul className="drop-list">
                  <li
                        className="drop-item"
                        onClick={handleProfileBalanceClick}
                      >
                        Wallet
                      </li> 
                  <li className="drop-item" onClick={handleLogout}>
                        Logout
                      </li> 
                </ul>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </header>
      </div>
      <main>
        <div className="privacy-n-termCondition">
          <div className="termCondition-container">
            <div className="aiml-card">
              <div className="ailm-card-header">
                <div>
                  <h3 className="page-title">Privacy Policy</h3>
                  <p className="page-date"></p>
                </div>
                <div>
                  <button
                    type="button"
                    className="aiml-btn"
                    onClick={handleCloseButton}
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="aiml-card-body">
                <h4 className="content-title">1. Introduction</h4>
                <p className="content-text">
                  NeevCloud (“we,” “our,” or “us”) is committed to protecting
                  the privacy of our users (“you” or “your”). This Privacy
                  Policy explains how we collect, use, disclose, and safeguard
                  your information when you use the NeevCloud GenAI Inference
                  Platform (the “Platform”) accessible at https://ai.ml/. Please
                  read this Privacy Policy carefully. If you do not agree with
                  the terms of this Privacy Policy, please do not use the
                  Platform.
                </p>
                <h4 className="content-title">2. Information We Collect</h4>
                <p className="content-text">
                  We may collect and process the following types of information:
                </p>
                <h5 className="content-subtitle">2.1 Personal Information</h5>
                <p className="content-text">
                  <span>Account Information:</span> When you create an account,
                  we collect information such as your name, email address, phone
                  number, and other contact details.
                </p>
                <p className="content-text">
                  <span>Profile Information:</span> You may provide additional
                  information, such as a profile picture and other personal
                  details.
                </p>
                <h5 className="content-subtitle">2.2 Usage Information</h5>
                <p className="content-text">
                  <span>Device Information:</span> We collect information about
                  the device you use to access the Platform, including the
                  device type, operating system, and browser type.
                </p>
                <p className="content-text">
                  <span>Log Information:</span> We collect log data about your
                  use of the Platform, including IP address, access times, pages
                  viewed, and the page you visited before navigating to our
                  Platform.
                </p>
                <h5 className="content-subtitle">2.3 Content</h5>
                <p className="content-text">
                  <span>User Content:</span> We collect and store any content
                  you upload, generate, or submit through the Platform,
                  including text, images, video, and audio files.
                </p>
                <h4 className="content-title">
                  3. How We Use Your Information
                </h4>
                <p className="content-text">
                  We may use the information we collect for various purposes,
                  including:
                </p>
                <ul>
                  <li>To provide, operate, and maintain the Platform.</li>
                  <li>To improve, personalize, and expand the Platform.</li>
                  <li>
                    To communicate with you, including providing customer
                    support.
                  </li>
                  <li>To process your transactions and manage your orders.</li>
                  <li>
                    To send you updates, promotions, and marketing
                    communications.
                  </li>
                  <li>
                    To monitor and analyze usage and trends to improve your
                    experience.
                  </li>
                  <li>
                    To detect, prevent, and address technical issues and
                    security threats.
                  </li>
                  <li>
                    To comply with legal obligations and protect our legal
                    rights.
                  </li>
                </ul>
                <h4 className="content-title">4. Sharing Your Information</h4>
                <p>
                  We do not sell or rent your personal information to third
                  parties. We may share your information in the following
                  circumstances:
                </p>
                <p className="content-text">
                  <span>Service Providers:</span> We may share your information
                  with third-party service providers who perform services on our
                  behalf, such as hosting, data analysis, and customer support.
                </p>
                <p className="content-text">
                  <span>Legal Requirements:</span> We may disclose your
                  information if required to do so by law or in response to
                  valid requests by public authorities (e.g., a court or a
                  government agency).
                </p>
                <p className="content-text">
                  <span>Business Transfers:</span> We may share or transfer your
                  information in connection with, or during negotiations of, any
                  merger, sale of company assets, financing, or acquisition of
                  all or a portion of our business.
                </p>
                <h4 className="content-title">5. Data Security</h4>
                <p className="content-text">
                  We implement appropriate technical and organizational measures
                  to protect your information from unauthorized access, use, or
                  disclosure. However, no method of transmission over the
                  internet or electronic storage is completely secure, and we
                  cannot guarantee the absolute security of your information.
                </p>
                <h4 className="content-title">6. Data Retention</h4>
                <p className="content-text">
                  We will retain your personal information only for as long as
                  is necessary for the purposes set out in this Privacy Policy,
                  and to the extent necessary to comply with our legal
                  obligations, resolve disputes, and enforce our policies.
                </p>
                <h4 className="content-title">7. Your Rights</h4>
                <p className="content-text">
                  Depending on your location, you may have the following rights
                  regarding your personal information:
                </p>
                <p className="content-text">
                  <span>Access:</span> You have the right to access and obtain a
                  copy of your personal information.
                </p>
                <p className="content-text">
                  <span>Rectification:</span> You have the right to request
                  correction of any inaccurate or incomplete personal
                  information.
                </p>
                <p className="content-text">
                  <span>Deletion:</span> You have the right to request the
                  deletion of your personal information, subject to certain
                  exceptions.
                </p>
                <p className="content-text">
                  <span>Restriction:</span> You have the right to request the
                  restriction of processing of your personal information.
                </p>
                <p className="content-text">
                  <span>Objection:</span> You have the right to object to the
                  processing of your personal information.
                </p>
                <p className="content-text">
                  <span>Portability:</span> You have the right to request the
                  transfer of your personal information to another entity.
                </p>
                <p className="content-text">
                  To exercise these rights, please contact us at
                  privacy@neevcloud.com.
                </p>
                <h4 className="content-title">8. Children’s Privacy</h4>
                <p className="content-text">
                  The Platform is not intended for use by individuals under the
                  age of 18. We do not knowingly collect personal information
                  from children under 18. If we become aware that we have
                  inadvertently collected personal information from a child
                  under 18, we will take steps to delete such information as
                  soon as possible.
                </p>
                <h4 className="content-title">
                  9. Changes to This Privacy Policy
                </h4>
                <p className="content-text">
                  We may update this Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  the Platform. You are advised to review this Privacy Policy
                  periodically for any changes. Changes to this Privacy Policy
                  are effective when they are posted on this page.
                </p>
                <h4 className="content-title">10. Contact Us</h4>
                <p className="content-text">
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at support@ai.ml. By using the
                  NeevCloud GenAI Inference Platform, you acknowledge that you
                  have read, understood, and agree to be bound by this Privacy
                  Policy.
                </p>
              </div>
              <div className="card-footer d-flex">
                <a href="mailto:support@ai.ml" className="aiml-primary-btn">
                  <img src={Phone} alt="icon" />
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LayoutPrivacy;
